import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import skewLine from "../images/ui/skewLine.svg"

export const StyledCard = styled.div`
  padding-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(58, 70, 91, 0.1),
    0 2px 4px 0 rgba(6, 41, 100, 0.1);
  background-color: #fff;
  height: 332px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledImg = styled(Img)`
  height: 100%;
  max-width: 100%;
`

export const Info = styled.div`
  &::before {
    content: "";
    background-image: url(${skewLine});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 30px;
    transform: translateY(-20px);
  }
`

export const Model = styled.p`
  font-size: 20px;
  letter-spacing: 0.3px;
  color: #062964;
  text-align: center;
  font-weight: 700;
`

const CarCard = ({ img, name }) => {
  return (
    <StyledCard>
      <StyledImg
        fluid={img.childImageSharp.fluid}
        alt={name}
        objectFit="contain"
      />
      <Info>
        <Model>{name}</Model>
      </Info>
    </StyledCard>
  )
}

export default CarCard
